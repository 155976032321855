<template>
  <tp-table :showPagination="false" :isNeedSerialNumber="false" :tableData="list" :columns="columns" />
</template>

<script>
const columns = [
  {
    label: '交易编号',
    prop: 'transaction_serial_number',
    minWidth: '150',
    customRender(h, row) {
      return <div>{row['transaction_serial_number']}</div>
    }
  },

  {
    label: '开始充电时间',
    prop: 'start_time',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['start_time']||'-'}</p>
    }
  },
  {
    label: '结束充电时间',
    prop: 'end_time',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['end_time']||'-'}</p>
    }
  },

  {
    label: '支付费用',
    prop: 'order_money',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['order_money']}</p>
    }
  },
  {
    label: '充电桩编号',
    prop: 'pile_gun_info',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['pile_gun_info']?.['pile_number']}</p>
    }
  },
  {
    label: '充电枪编号',
    prop: 'pile_gun_info',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['pile_gun_info']?.['gun_number']}</p>
    }
  }
]
export default {
  name: 'OrderCharge',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columns
    }
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped></style>
