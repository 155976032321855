import { http } from '@/http/axios.js'
// 列表
export function getListAPI(params) {
  return http({
    url: '/company/order/order/list',
    method: 'get',
    params
  })
}
// 详情
export function getDetailAPI(id) {
  return http({
    url: '/company/order/order/detail',
    method: 'get',
    params: { id }
  })
}

