<template>
  <div class="wrapper">
    <div class="process">
      <div class="start">下单时间</div>
      <div class="end">订单结束时间</div>
    </div>
    <div class="order-time">
      <span>{{ order_process?.start_time || '-' }}</span>
      <span>{{ order_process?.end_time || '-' }}</span>
    </div>
    <admin-title title="基本信息"></admin-title>
    <el-descriptions style="margin-bottom: 20px" direction="vertical" :column="5" border>
      <el-descriptions-item label-class-name="my-label" label="订单号">{{ basic_info?.order_no }}</el-descriptions-item>
      <el-descriptions-item label-class-name="my-label" label="用户">
        <!-- <p>昵称：{{ basic_info?.user?.nickname }}</p> -->
        <p>手机号：{{ basic_info?.user?.mobile }}</p>
      </el-descriptions-item>
      <el-descriptions-item label-class-name="my-label" label="订单金额">
        <p>总费用：{{ Number(basic_info?.order_money?.seat_money) + Number(basic_info?.order_money?.charging_money) }}</p>
        <!-- <p>支付费用：{{ basic_info?.order_money?.order_money }}</p> -->
        <p>充电费用：{{ basic_info?.order_money?.charging_money }}</p>
        <p>占位费用：{{ basic_info?.order_money?.seat_money }}</p>
      </el-descriptions-item>
      <el-descriptions-item label-class-name="my-label" label="交易状态">
        <el-tag size="small" :type="getType">{{ basic_info?.order_status_dsc }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label-class-name="my-label" label="时间">
        <!-- <p>支付时间：{{ basic_info?.pay_time || '-' }}</p> -->
        <p>订单结束时间：{{ basic_info?.end_time || '-' }}</p>
      </el-descriptions-item>
    </el-descriptions>
    <admin-title title="充电订单"></admin-title>
    <div class="order-charge">
      <OrderCharge :list="order_charges"></OrderCharge>
    </div>
    <admin-title title="占位订单"></admin-title>
    <div class="order-seats">
      <OrderSeats :list="order_seats"></OrderSeats>
    </div>
    <div style="height: 80px"></div>
  </div>
</template>

<script>
import { getDetailAPI } from './api'
import OrderCharge from './modules/order-charge.vue'
import OrderSeats from './modules/order-seats.vue'
export default {
  name: 'Detail',
  components: { OrderCharge, OrderSeats },
  data() {
    return {
      detailInfo: {}
    }
  },
  provide() {
    return {
      context: this
    }
  },
  computed: {
    // 过程
    order_process() {
      return this.detailInfo.order_process
    },
    // 基本信息
    basic_info() {
      return this.detailInfo.basic_info
    },
    // 充电订单
    order_charges() {
      return this.detailInfo.order_charges
    },
    // 占位订单
    order_seats() {
      return this.detailInfo.order_seats
    },
    getType() {
      let type = 'warning'
      if (this.detailInfo.basic_info) {
        const { pay_status } = this.detailInfo.basic_info
        if (pay_status) {
          switch (pay_status) {
            case 0:
              type = 'warning'
              break
            case 1:
              type = 'primary'
              break
            case 2:
              type = 'success'
              break
            case 3:
              type = 'warning'
              break
            case 4:
              type = 'warning'
              break
            case 5:
              type = 'danger'
              break

            default:
              break
          }
        }
      }

      return type
    }
  },
  mounted() {
    this.getDetail()
  },

  methods: {
    async getDetail() {
      this.detailInfo = await getDetailAPI(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
  }
  .process {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #8dc4ff;
    border-radius: 13px;

    color: #fff;
    .start {
      padding: 3px 0;
      text-align: center;
      flex: 1;
      background-color: #5fa2f4;
      border-radius: 13px;
    }
    .end {
      padding: 3px 0;
      text-align: center;
      flex: 1;
    }
  }
  .order-time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    span {
      flex: 1;
      text-align: center;
    }
  }
  .order-charge {
    overflow-y: scroll;
    max-height: 400px;
    margin-bottom: 20px;
    &::-webkit-scrollbar {
      width: 0px;
      background-color: #ccc;
    }
  }
}
</style>
